:root {
  --1grad-color: #6c63ff;
  --2grad-color: #FF6C63;
  --3grad-color: #63FF6C;
}

@import url('https://fonts.googleapis.com/css2?family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

.App {
  text-align: center;
  height: 100vh;
  display: block;
}

.tf_font {
  font-family: 'Urbanist', sans-serif;
}

.blur_body {
  position: relative;
  width: 100%;
  height: 80vh; /* Adjust height as needed */
  overflow: hidden; /* Ensure content doesn't overflow */
}

.blur_body::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover; /* Cover the entire area */
  background-position: center; /* Center the image */
  filter: blur(12px); /* Adjust blur radius as needed */
  z-index: -1; /* Ensure it is behind the content */
}

.blur_body::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure it is behind the content but above the image */
}

.bg_gradient {
  background-color: var(--1grad-color) !important;
}

.hero_gradient {
  background: linear-gradient(180deg, var(--1grad-color), #fdfdfd);
}

@keyframes gradientChange {
  0% {
    background: var(--1grad-color);
  }
  50% {
    background: var(--2grad-color);
  }
  100% {
    background: var(--1grad-color);
  }
}

.xbg_gradient {
  animation: gradientChange 5s infinite;
}

.gradient_text {
  background: linear-gradient(to right, var(--1grad-color), var(--2grad-color));
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  line-height: 1.13;
}

.gradient_bg {
  background: linear-gradient(to right, var(--1grad-color), var(--2grad-color));
}

.rainbow_text {
  background: linear-gradient(to right, red, orange, green, blue, indigo);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.navbar {
  top: -100px !important; /* Start above the viewport */
  transition: top 0.5s ease-in-out;
  z-index: 1000; /* Ensure it stays on top of other elements */
}

.navbar.visible {
  top: 0 !important; /* Move to the top of the viewport */
}

@media (max-width: 768px) {
  #navbar-block {
    border-radius: 0 !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }
}
